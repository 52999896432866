<template>
  <ion-content>
    <div class="site-content">
      <div style="background-color: #56B35A;height: 200px; padding-top: 100px;margin-bottom: 30px">
        <div class="container">
          <div class="lb-info">
            <h2 style="color: #ffffff;font-size: 30px">Scrapl.us</h2>
          </div><!-- .lb-info -->
        </div>
      </div><!-- .landing-banner -->
      <div class="container">
        <div class="lb-button" v-html="consentContent">
        </div>
      </div>
    </div>
  </ion-content>


</template>

<script>
import {inject, ref} from "@vue/runtime-core";
import {IonContent} from "@ionic/vue";

export default {
  name: "Policies",
  components: {IonContent},
  setup() {
    const $api = inject('api');
    const consentContent = ref('');
    consentContent.value = $api.consent;
    return {
      consentContent
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>